// import MicromodalEs from 'micromodal';
// MicromodalEs.init();

import {processOneTimeDialogToCookie} from '/resources/src/v3/common/js/helper.js';

window.onload = function () {
	(function () {
		processOneTimeDialogToCookie('dialogModal', 'ageVerified');
	})();
};
